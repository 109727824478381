import React from 'react';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import $ from 'jquery'

import 'diagram-js/assets/diagram-js.css'
import 'bpmn-font/dist/css/bpmn-embedded.css'

const emptyDiagram = `<?xml version="1.0" encoding="UTF-8"?>
  <bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd">
    <bpmn2:process id="Process_1" isExecutable="false" />
    <bpmndi:BPMNDiagram id="BPMNDiagram_1">
      <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1" />
    </bpmndi:BPMNDiagram>
  </bpmn2:definitions>`

export default class Bpmn extends React.Component {
  componentWillReceiveProps(props) {
    if (props.diagram)
      this.openDiagram(props.diagram)
    else
      this.openDiagram(emptyDiagram)
  }

  componentDidMount() {
      this.bpmnModeler = new BpmnModeler({
        container: '#canvas',
        keyboard: {
          bindTo: window
        }
      });

      if (this.props.diagram) this.openDiagram(this.props.diagram)
      else this.openDiagram(emptyDiagram)
      // $.get(diagramUrl, openDiagram, 'text');
      // $('#save-button').click(exportDiagram);
  }

  exportDiagram = () => {
    this.bpmnModeler.saveXML({ format: true }, (err, xml) => {
      if (err) {
        return console.error('could not save BPMN 2.0 diagram', err);
      }
      this.props.sync(xml)
    });
  }

  openDiagram = (bpmnXML) => {
    this.bpmnModeler.importXML(bpmnXML, (err) => {
      if (err) {
        return console.error('could not import BPMN 2.0 diagram', err);
      }
      // access modeler components
      var canvas = this.bpmnModeler.get('canvas');
      var overlays = this.bpmnModeler.get('overlays');
      // zoom to fit full viewport
      canvas.zoom('fit-viewport');
      // attach an overlay to a node
      // overlays.add('SCAN_OK', 'note', {
      //   position: {
      //     bottom: 0,
      //     right: 0
      //   }
      // });
      // add marker
      // canvas.addMarker('SCAN_OK', 'needs-discussion');
    });
  }

  render() {
    return (
      <div  className="process">
        <div onClick={this.exportDiagram}>Zapisz</div>
        <div  style={{height:"700px"}} id="canvas"></div>
      </div>
    );
  }
}

import React, { Component } from 'react'
import {Route} from 'react-router-dom'
import {StorworkInit, JWT} from 'storwork-stores'
import client from 'socket.io-client'
import Bpmn from './Bpmn'


class Main extends Component {
  diagram = null
  socket = null
  query = null
  responce = null

  componentDidMount () {
    const id = this.props.match.params.id
    console.log("componentDidMount")
    const query = `{
      search (id:${id}, type:"generalobject") {
        ... on GeneralObject {
          id
          json
          type
          indexType
        }
      }
    }`
    this.initSocket(query)
  }

  setQuery = query => {
    this.query = query
    this.socket.emit('newQuery', this.query)
  }

  sync = (xml) => {
    console.log('sync')
    if (this.responce && this.diagram) {
      let json = JSON.parse(this.responce.search[0].json)
      json.content = window.btoa(xml)
      this.responce.search[0].json = JSON.stringify(json)
      this.socket.emit('sync', this.responce)
    }
  }

  getData = data => {
    console.log("nowe dane",data)
    if (data.search[0] && data.search[0].type === "bpmn"){
      this.responce = data
      this.diagram = window.atob(JSON.parse(data.search[0].json).content)
      console.log(this.diagram);
      this.forceUpdate()
    }
  }

  initSocket = (query) => {
    const socket = client('https://mega.storwork.pl', { //188.116.2.82:3001 //http://localhost:3001
      query: {
        authorization: "Bearer " + JWT.get()
      }
    })
    socket.on('connect', () => {
      console.log('Connected')
      this.socket = socket
      this.socket.on('disconnect', function(){
        console.log('Disconnected')
      })
      this.socket.on('newDataLoaded', this.getData)
      if (query) {
        console.log('Initial query set')
        this.setQuery(query)
      }
    })
  }

  render () {
    return <Bpmn diagram={this.diagram} sync={this.sync} />
  }
}


class App extends Component {
  render() {
    return <StorworkInit loggedIn={
      <Route exact path='/:id' component={Main} />
    } />
  }
}

export default App;
